$(document).ready(function() {


 // SECTIONS SORTABLE
 if(document.getElementById("sectionTileContainer")) {
   var container = document.getElementById("sectionTileContainer");
   var sort = Sortable.create(container, {
     handle: ".btn-success",
     ghostClass: "sortable-ghost",
     onUpdate: function (evt/**Event*/){
       var sectionNavigationOrder;
       sectionNavigationOrder = [];
       $("#sectionTileContainer li").each(function(index) {
         if(index % 2 != 0) {
           $(this).find('table').removeClass('table-striped');
         } else {
           $(this).find('table').addClass('table-striped');
         }
         var index_plus_one;
         index_plus_one = index + 1;
         $(this).data("current-order", index_plus_one);
         $(this).find("span.order").html(index_plus_one);
         return sectionNavigationOrder.push({
           id: $(this).data("id"),
           order: $(this).data("current-order")
         });
       });
       return $.ajax({
         type: "POST",
         url: "/admin/sections/update-order",
         data: {
           orders: sectionNavigationOrder,
           _token: $('meta[name=csrf_token]').attr('content')
         }
       });
     }
   });
 }


 // UNITS SORTABLE
 if(document.getElementById("unitTileContainer")) {
   var container = document.getElementById("unitTileContainer");
   var sort = Sortable.create(container, {
     handle: ".btn-success",
     ghostClass: "sortable-ghost",
     onUpdate: function (evt/**Event*/){
       var unitNavigationOrder;
       unitNavigationOrder = [];
       $("#unitTileContainer li").each(function(index) {
         if(index % 2 != 0) {
           $(this).find('table').removeClass('table-striped');
         } else {
           $(this).find('table').addClass('table-striped');
         }
         var index_plus_one;
         index_plus_one = index + 1;
         $(this).data("current-order", index_plus_one);
         $(this).find("span.order").html(index_plus_one);
         return unitNavigationOrder.push({
           id: $(this).data("id"),
           order: $(this).data("current-order")
         });
       });
       return $.ajax({
         type: "POST",
         url: "/admin/units/update-order",
         data: {
           orders: unitNavigationOrder,
           _token: $('meta[name=csrf_token]').attr('content')
         }
       });
     }
   });
 }


 // TEAM MEMBERS SORTABLE
 if(document.getElementById("teamTileContainer")) {
   var container = document.getElementById("teamTileContainer");
   var sort = Sortable.create(container, {
     handle: ".btn-success",
     ghostClass: "sortable-ghost",
     onUpdate: function (evt/**Event*/){
       var teamNavigationOrder;
       teamNavigationOrder = [];
       $("#teamTileContainer li").each(function(index) {
         if(index % 2 != 0) {
           $(this).find('table').removeClass('table-striped');
         } else {
           $(this).find('table').addClass('table-striped');
         }
         var index_plus_one;
         index_plus_one = index + 1;
         $(this).data("current-order", index_plus_one);
         $(this).find("span.order").html(index_plus_one);
         return teamNavigationOrder.push({
           id: $(this).data("id"),
           order: $(this).data("current-order")
         });
       });
       return $.ajax({
         type: "POST",
         url: "/admin/units/update-order",
         data: {
           orders: teamNavigationOrder,
           _token: $('meta[name=csrf_token]').attr('content')
         }
       });
     }
   });
 }


});

