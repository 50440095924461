var Vue = require('vue');
Vue.use(require('vue-resource'));

Vue.filter('title-case', function (value) {
  return value.split("_").map(function(i){return i[0].toUpperCase() + i.substring(1)}).join(" ");
});

new Vue({
	el: '#admin--products--create',
	data: {
    textColumns: ['name', 'code', 'manufacturer_name', 'manufacturer_code', 'outdoor_use', 'stackable', 'folding', 'arms', 'pedastool_base', 'four_legged_base', 'low_feet', 'sled_base', 'swivel_base', 'floor_fixed', 'wheeled_base', 'boxed_base', 'without_back_rest', 'adjustable_height', 'weave', 'quilting', 'width', 'depth', 'seat_height', 'overall_height', 'fabric_needed', 'buying_price_eur', 'buying_price_gbp', 'notes', 'optional_extras', 'price_band'],
		values: {}
  },
	ready: function() {
		//this.$http.get('/api/v1/products', function (data, status, request) {
		//	this.$set('gridData', data)
		//})
	}
})

