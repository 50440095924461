var Vue = require('vue');
Vue.use(require('vue-resource'));
Vue.use(require('vue-dnd'));

Vue.filter('title-case', function (value) {
  return value.split("_").map(function(i){return i[0].toUpperCase() + i.substring(1)}).join(" ");
});

new Vue({
	el: '#admin--products--images--view',
	replace: true,
	data: {
		loaded: false,
		loadingDelete: false,
    searchQuery: '',
    columns: ['filename'],
		sortKey: '',
		filterKey: '',
		reversed: {},
    gridData: [],
		gridColumns: [],
		csrfToken: '',
		productId: 0,
  },
	methods: {
		refresh: function() {
			this.$http.get('/api/v1/products/' + this.productId + '/images', function (data, status, request) {
				this.$set('gridData', data)
				this.$set('loaded', true);
			})
		},
		updateAltText: function(index) {
			var row = this.gridData[index];
			var data = {
				id: row.id,
				alt_text: row.alt_text,
				_token: this.csrfToken
			};
			this.$http.patch('/admin/images/' + row.id,  data, function (data, status, request) {
				
			}).error(function (data, status, request) {
			});
		},
		move: function(from, to, id, tag, data) {
			var tmp = from[data.index];
			from.splice(data.index, 1);
			to.splice(id, 0, tmp);

		},
		sort: function(list, id, tag, data) {
			var tmp = list[data.index];
			list.splice(data.index, 1);
			list.splice(id, 0, tmp);
			var images;
			images = [];
			list.forEach(function(image, order) {
				images.push({
					id: image.id,
					order: order
				});
			});

			var data;
			data['images'] = images;
			data['_token'] = this.csrfToken;

			this.$http.patch('/admin/products/' + this.productId + '/images',  data, function (data, status, request) {
				
			}).error(function (data, status, request) {
			});
		},
		onDelete: function(entry, key) {
			var _this = this;
			if(confirm('Are you sure you want to delete this item?')) {
				_this.$set('loadingDelete', true);
				var data = {
					_token: _this.csrfToken
				};
				_this.$http.delete('/admin/images/' + entry.id,  data, function (data, status, request) {
					_this.gridData.forEach(function(dbentry, dbkey) {
						if(dbentry.id == entry.id) {
							_this.gridData.$remove(dbkey);
							_this.$set('loadingDelete', false);
						}
					});
				}).error(function (data, status, request) {
        });
			}
		}
	},
	ready: function() {
		this.$http.get('/api/v1/products/' + this.productId + '/images', function (data, status, request) {
			this.$set('gridData', data)
			this.$set('loaded', true);
		})
	},
	compiled: function () {
    // initialize reverse state
    var self = this;
    this.columns.forEach(function (key) {
      self.reversed.$add(key, false)
    })
  },
})

