var Vue = require('vue');
Vue.use(require('vue-resource'));

Vue.filter('title-case', function (value) {
  return value.split("_").map(function(i){return i[0].toUpperCase() + i.substring(1)}).join(" ");
});

new Vue({
	el: '#admin--products--view',
	replace: true,
	data: {
		loaded: false,
		loadingDelete: false,
    searchQuery: '',
    columns: ['name', 'manufacturer_name', 'manufacturers_name', 'manufacturers_code', 'colour_count', 'buying_price_eur', 'height_name', 'type_name', 'image_count'],
		sortKey: '',
		filterKey: '',
		reversed: {},
    gridData: [],
		gridColumns: [],
		csrfToken: '',
  },
	methods: {
		sortBy: function(key) {
			this.sortKey = key
			this.reversed[key] = !this.reversed[key]
		},
		onDelete: function(entry, key) {
			var _this = this;
			if(confirm('Are you sure you want to delete this item?')) {
				_this.$set('loadingDelete', true);
				var data = {
					_token: _this.csrfToken
				};
				_this.$http.delete('/admin/products/' + entry.id,  data, function (data, status, request) {
					_this.gridData.forEach(function(dbentry, dbkey) {
						if(dbentry.id == entry.id) {
							_this.gridData.$remove(dbkey);
							_this.$set('loadingDelete', false);
						}
					});
				}).error(function (data, status, request) {
        });
			}
		}
	},
	ready: function() {
		this.$http.get('/api/v1/products', function (data, status, request) {
			this.$set('gridData', data)
			this.$set('loaded', true);
		})
	},
	compiled: function () {
    // initialize reverse state
    var self = this;
    this.columns.forEach(function (key) {
      self.reversed.$add(key, false)
    })
  },
})
